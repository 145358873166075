import React, { useEffect, useState } from 'react';
import { ParticipantView } from '../../views/ParticipantView';
import { Box, Typography } from '@mui/material';
import { palette } from '../../palette';
import { AdminGoalCardViewCreator } from 'view-creator/AdminGoalCardViewCreator';
import { GoalSummaryView, Lookup, RawGoal } from '@views';
import { GoalCompletionStatus } from 'components/GoalTracking/useGoalTrackerState';
import logger from 'utils/logger';
import { ActivityLookupViewCreator } from '@viewCreators';
import { ApiResponse, GoalStore } from '@stores';
import { ExistingGoal } from 'views/ExistingGoal';
import { ToDate } from 'utils/DateUtils';

interface CohortUserGoalEditorProps {
  cohortUser: ParticipantView;
}

export const CohortUserGoalEditor: React.FC<CohortUserGoalEditorProps> = ({
  cohortUser,
}) => {
  const goalStore = new GoalStore();
  const [goals, setGoals] = useState<{
    goals: GoalSummaryView[];
    progress: GoalCompletionStatus;
  }>({
    goals: [],
    progress: {},
  });
  // TODO: Add jail card administration functionality
  const [jailCards, setJailCards] = useState<number>(0);

  // TODO: Add activity administration functionality
  const [activities, setActivities] = useState<Lookup[]>([]);

  // On mount (or when the cohortUser changes) fetch activities and goals.
  useEffect(() => {
    const initializeGoalState = async () => {
      await fetchActivities().catch((err) => logger.error(err));
      await loadGoalCardView();
    };

    initializeGoalState();
  }, [cohortUser]);

  const fetchActivities = async () => {
    const view = await new ActivityLookupViewCreator().createView();
    setActivities(view.activities);
  };

  const loadGoalCardView = async () => {
    const view = await new AdminGoalCardViewCreator().CreateView(cohortUser.id);
    setGoals(view.goalsWithProgress);
    setJailCards(view.jailCards);
  };

  const handleSave = async (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement>,
    goalId: number
  ) => {
    event.preventDefault();
    const goalToSave = goals.goals.find((goal) => goal.id === goalId);

    if (!goalToSave) {
      logger.error('Goal not found');
      return;
    }

    const formattedGoal: ExistingGoal = {
      id: goalToSave.id,
      activityId: goalToSave.activity,
      name: `I commit to ${
        activities.find((activity) => activity.id === goalToSave.activity)
          ?.name || 'Unknown Activity'
      } ${goalToSave.frequency} Times per Week for ${goalToSave.duration} Minutes`,
      duration: goalToSave.duration,
      cohortUserId: cohortUser.id,
      frequency: goalToSave.frequency,
      timeframeId: 1,
      endDate: goalToSave.endDate,
      startDate: goalToSave.startDate,
    };

    try {
      const response: ApiResponse = await goalStore.UpdateGoal(formattedGoal);
      if (response.status === 'SUCCESS') {
        window.alert('Goal saved successfully');
      } else {
        window.alert('Failed to save goal');
        logger.error('Failed to save goal:', response);
      }
    } catch (error) {
      window.alert('Failed to save goal');
      logger.error('Error saving goal:', error);
    }
  };

  const handleDelete = async (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement>,
    goalId: number
  ) => {
    event.preventDefault();
    if (!window.confirm('Are you sure you want to delete this goal?')) {
      return;
    }
    try {
      const response: ApiResponse = await goalStore.DeleteGoal(goalId);
      if (response.status === 'SUCCESS') {
        window.alert('Goal deleted successfully');
        const newGoals = goals.goals.filter((goal) => goal.id !== goalId);
        setGoals({ ...goals, goals: newGoals });
      } else {
        window.alert('Failed to delete goal');
        logger.error('Failed to delete goal:', response);
      }
    } catch (error) {
      window.alert('Failed to delete goal');
      logger.error('Error deleting goal:', error);
    }
  };

  const handleCreateGoal = async (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement>,
    newGoalIndex: number
  ) => {
    event.preventDefault();
    const newGoal = goals.goals[newGoalIndex];

    const formattedGoals: RawGoal[] = [
      {
        activityId: newGoal.activity,
        name: `I commit to ${
          activities.find((activity) => activity.id === newGoal.activity)
            ?.name || 'Unknown Activity'
        } ${newGoal.frequency} Times per Week for ${newGoal.duration} Minutes`,
        duration: newGoal.duration,
        durationTimeframeId: 1,
        cohortUserId: cohortUser.id,
        frequency: newGoal.frequency,
        timeframeId: 1,
        endDate: ToDate(newGoal.endDate),
        startDate: ToDate(newGoal.startDate),
      },
    ];

    try {
      const response: ApiResponse = await goalStore.Write(formattedGoals);
      if (response.status === 'SUCCESS') {
        window.alert('Goal created successfully');
      } else {
        window.alert('Failed to create goal');
        logger.error('Failed to create goal:', response);
      }
    } catch (error) {
      window.alert('Failed to create goal');
      logger.error('Error creating goal:', error);
    }
  };

  const handleCancelNewGoal = (index: number) => {
    const newGoals = goals.goals.filter((_, i) => i !== index);
    setGoals({ ...goals, goals: newGoals });
  };

  const handleAddGoalRow = () => {
    // If there is already a new goal row, don't add another.
    if (goals.goals.find((goal) => goal.id === 0)) {
      window.alert(
        'Please save or cancel the current goal before adding another'
      );
      return;
    }
    const newGoal: GoalSummaryView = {
      id: 0,
      goalText: '',
      activity: activities.length > 0 ? activities[0].id : 0,
      duration: 0,
      frequency: 0,
      startDate: '',
      endDate: '',
      timeframe: 1,
      goalsComplete: 0,
    };
    setGoals({ ...goals, goals: [...goals.goals, newGoal] });
  };

  return (
    <Box
      borderRadius={8}
      border={1}
      borderColor={palette.background.border}
      display="block"
      position="relative"
      padding={4}
      margin={4}
    >
      <Typography
        variant="subtitle1"
        sx={{
          backgroundColor: palette.background.paper,
          position: 'absolute',
          top: -32,
          left: 48,
          padding: 2,
        }}
      >
        Goals
      </Typography>
      {goals.goals.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Goal</th>
              <th>Activity</th>
              <th style={{ width: '75px' }}>Duration (minutes)</th>
              <th style={{ width: '100px' }}>Frequency (per week)</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {goals.goals.map((goal, index) => (
              <tr key={index}>
                <td>
                  <p style={{ fontSize: '14px' }}>
                    I commit to{' '}
                    {activities.find(
                      (activity) => activity.id === goal.activity
                    )?.name || 'Unknown Activity'}{' '}
                    {goal.frequency} Times per Week for {goal.duration} Minutes
                  </p>
                </td>
                <td>
                  <select
                    value={goal.activity}
                    onChange={(e) => {
                      const newGoals = [...goals.goals];
                      newGoals[index].activity = Number(e.target.value);
                      setGoals({ ...goals, goals: newGoals });
                    }}
                  >
                    {activities.map((activity) => (
                      <option key={activity.id} value={activity.id}>
                        {activity.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    type="number"
                    value={goal.duration}
                    style={{ width: '75px' }}
                    onChange={(e) => {
                      const newGoals = [...goals.goals];
                      newGoals[index].duration = Number(e.target.value);
                      setGoals({ ...goals, goals: newGoals });
                    }}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={goal.frequency}
                    style={{ width: '100px' }}
                    onChange={(e) => {
                      const newGoals = [...goals.goals];
                      newGoals[index].frequency = Number(e.target.value);
                      setGoals({ ...goals, goals: newGoals });
                    }}
                  />
                </td>
                <td>
                  <input
                    type="date"
                    value={goal.startDate || ''}
                    onChange={(e) => {
                      const newGoals = [...goals.goals];
                      newGoals[index].startDate = e.target.value;
                      setGoals({ ...goals, goals: newGoals });
                    }}
                  />
                </td>
                <td>
                  <input
                    type="date"
                    value={goal.endDate || ''}
                    onChange={(e) => {
                      const newGoals = [...goals.goals];
                      newGoals[index].endDate = e.target.value;
                      setGoals({ ...goals, goals: newGoals });
                    }}
                  />
                </td>
                <td>
                  {goal.id === 0 ? (
                    <>
                      <button
                        onClick={(event) => handleCreateGoal(event, index)}
                      >
                        Save
                      </button>
                      <button onClick={() => handleCancelNewGoal(index)}>
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button onClick={(event) => handleSave(event, goal.id)}>
                        Save
                      </button>
                      <button onClick={(event) => handleDelete(event, goal.id)}>
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <br />
      <button onClick={handleAddGoalRow}>Add Goal</button>
    </Box>
  );
};
