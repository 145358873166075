import { GoalsWithStatus } from '@components';
import { GoalStore } from '@stores';

interface GoalCardView {
  goalsWithProgress: GoalsWithStatus;
  jailCards: number;
}

export class AdminGoalCardViewCreator {
  constructor(private readonly goalStore: GoalStore = new GoalStore()) {}

  public async CreateView(cohortUserId: number): Promise<GoalCardView> {
    // Get the content from the API
    const content = await this.goalStore.AdminReadByCohortUserId(cohortUserId);
    const goals = content.result.goals;
    const progress = content.result.progress;
    const goalsWithProgress = {
      goals: goals,
      progress: progress,
    };
    const jailCards = content.result.participantJailCards;

    // Return the view
    return {
      goalsWithProgress: goalsWithProgress,
      jailCards: jailCards,
    };
  }
}
