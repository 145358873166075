import React, {
  Fragment,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import './Dashboard.scss';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router';
import {
  ActivityHistoryView,
  DashboardViewModel,
  GoalView,
  QuestionView,
  RawGoal,
  ResponseView,
  valueToActivityString,
  valueToString,
} from '@views';
import {
  ActivityHistoryViewCreator,
  DashboardViewCreator,
  InfoPanelViewCreator,
  JoyrideContentViewCreator,
} from '@viewCreators';
import { Container, Stack, SxProps } from '@mui/material';
import {
  DebugPanel,
  ExerciseInDisguise,
  GoalTrackerCard,
  InfoDialog,
  LeaderCard,
  PaymentModal,
  PotCard,
  ScienceSomethingCard,
  SurveyDrawer,
  TeamCard,
} from '@components';
import CreateGoalAction from '../../actions/CreateGoalAction';
import { SaveSurveyResponsesAction } from '@actions';
import { DateTime } from 'luxon';
import logger from '../../utils/logger';
import Joyride, {
  ACTIONS,
  CallBackProps,
  LIFECYCLE,
  Step,
} from 'react-joyride';
import { palette } from '../../palette';
import { ToDate, ToDateTime } from '../../utils/DateUtils';
import { DashboardHeader } from './DashboardHeader';

export const Dashboard: React.FC<PropsWithChildren> = () => {
  const [cookies] = useCookies(['profile']);
  const initialGoalFormState: GoalView = {
    name: '',
    activityId: -1,
    duration: 0,
    durationTimeframe: 2,
    frequency: 1,
    timeframe: 1,
    startDate: DateTime.now().startOf('week', { useLocaleWeeks: true }),
    endDate: DateTime.now()
      .startOf('week', { useLocaleWeeks: true })
      .plus({ week: 4 })
      .plus({ day: -1 }),
    datesEnabled: false,
  };
  const params = useParams();
  const [view, setView] = useState(DashboardViewModel.Empty);
  const [week1, setWeek1] = useState<boolean>(false);
  const [groupStartDate, setGroupStartDate] = useState<DateTime>();

  const [questions, setQuestions] = useState<QuestionView[]>([]);
  const [reload, setReload] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(true);
  const [finishVisible, setFinishVisible] = useState<boolean>(false);
  const [nextDisabled, setNextDisabled] = useState<boolean>(true);
  const [activityHistoryView, setActivityHistoryView] =
    useState<ActivityHistoryView>({ items: [] });
  const [formState, setFormState] = useState<GoalView[]>([
    initialGoalFormState,
    initialGoalFormState,
  ]);
  const [steps, setSteps] = useState<Step[]>([]);

  const [infoWithDismissedStatus, setInfoWithDismissedStatus] = useState<
    {
      content: any | undefined;
      dismissed: boolean;
      offset: number;
      title: string;
    }[]
  >([]);
  const [infoDialogState, setInfoDialogState] = useState<{
    open: boolean;
    infoDialogContent:
      | { title: string; content: any; offset: number }
      | undefined;
  }>({ open: false, infoDialogContent: undefined });

  useEffect(() => {
    // Check if there's another info item to display
    const nextInfo = infoWithDismissedStatus
      .filter((info) => !info.dismissed)
      .sort((a, b) => a.offset - b.offset)[0];

    // If there is an info item to display, set the dialog state and open the dialog
    if (nextInfo) {
      setInfoDialogState((prev) => ({
        ...prev,
        open: Boolean(nextInfo.content) && !nextInfo.dismissed,
        infoDialogContent: {
          title: nextInfo.title,
          content: nextInfo.content,
          offset: nextInfo.offset,
        },
      }));
    }
  }, [infoWithDismissedStatus]);

  useEffect(() => {
    if (!reload) return;

    const resetState = () => {
      setActiveStep(0);
      setIsLoading(true);
      setReload(false);
    };

    const loadJoyrideContent = () => {
      return new JoyrideContentViewCreator().createView().then(setSteps);
    };

    const loadDashboardView = async () => {
      const result = await new DashboardViewCreator().createViewWithArg({
        groupId: Number(params['groupId']),
        userId: String(cookies.profile.id),
      });
      allowNext(result.section.questions[activeStep]);
      allowFinish(activeStep);
      return result;
    };

    const loadInfoPanel = async (groupStartDate: DateTime, groupId: number) => {
      // Get all the info panel items
      const info = await new InfoPanelViewCreator().createView(
        ToDateTime(groupStartDate),
        groupId
      );

      // Set the info items with their dismissed status in state. This will trigger the info dialog to show if needed
      setInfoWithDismissedStatus(info);
    };

    const updateFormState = (groupStartDate: DateTime | string) => {
      const start =
        typeof groupStartDate === 'string'
          ? DateTime.fromISO(groupStartDate)
          : groupStartDate;
      setFormState((original) =>
        original.map((state) => ({
          ...state,
          endDate: start.plus({ day: 34 }),
          startDate: start.plus({ day: 7 }),
        }))
      );
    };

    const loadActivityHistory = (cohortUserId: number) => {
      return new ActivityHistoryViewCreator()
        .createView(cohortUserId)
        .then(setActivityHistoryView);
    };

    const initializeViews = async () => {
      resetState();

      const result = await loadDashboardView();
      await loadInfoPanel(ToDateTime(result.groupStartDate), result.groupId);

      // Convert the group start date via ToDate (ensures we don't have UTC -> local timezone conversion issues)
      const luxonGroupStartDate = ToDate(result.groupStartDate);

      // Update using the new group start date
      updateFormState(luxonGroupStartDate);

      // Update result with the new group start date
      result.groupStartDate = luxonGroupStartDate;
      setGroupStartDate(luxonGroupStartDate);

      // Set the view state
      setView(result);

      // Load the Joyride content
      await loadJoyrideContent();

      if (result.section) {
        setQuestions(result.section.questions);
      }

      await loadActivityHistory(result.cohortUserId);
      setIsLoading(false);
    };

    initializeViews();
  }, [reload]);

  const calculateWeek1 = (): boolean => {
    const now = DateTime.now();
    const startDate = groupStartDate;
    // If startDate is not a DateTime, we don't have the proper value from the back end yet
    if (!startDate || !(startDate instanceof DateTime)) {
      return false;
    }

    // Calculate the number of days between the start date and now
    const days = now.diff(startDate, 'days').days;
    return days >= 0 && days < 7;
  };

  useEffect(() => {
    setWeek1(calculateWeek1());
  }, [groupStartDate]);

  const [showGoalModal, setShowGoalModal] = useState(false);
  const handleUpdateGoal = () => {
    setShowGoalModal(!showGoalModal);
  };

  const handleClose = async (
    e: any,
    reason: 'SAVE' | 'CANCEL',
    goals: RawGoal[]
  ) => {
    logger.debug('goal dialog ->', reason);
    setShowGoalModal(false);
    if (reason === 'SAVE') {
      const result = await new CreateGoalAction().Execute(goals);
      logger.debug(JSON.stringify(result, null, 2));
      setReload(true);
    }
  };
  const backgroundStyles: SxProps = {
    background: '#f4f4f4',
    width: '100vw',
    maxWidth: '100%',
    color: palette.primary,
  };

  const [activeStep, setActiveStep] = useState<number>(0);
  const handleNext = () => {
    allowFinish(activeStep + 1);
    allowNext(questions[activeStep + 1]);
    setActiveStep(activeStep + 1);
  };
  const handlePrev = () => {
    allowFinish(activeStep - 1);
    allowNext(questions[activeStep - 1]);
    setActiveStep(activeStep - 1);
  };

  function allowFinish(currentStep: number) {
    const questionsAnswered = questions.filter((q) =>
      q.responses.some((r) => Boolean(r.textResponse))
    ).length;
    const showFinish =
      questionsAnswered === questions.length &&
      currentStep === questions.length - 1;
    setFinishVisible(showFinish);
  }

  function allowNext(currentQuestion: QuestionView) {
    if (currentQuestion) {
      // If the question is a goal question, we need to check if the form is filled out properly
      if (currentQuestion.id === 130 || currentQuestion.id === 140) {
        // Set the right form state based on the question ID (130 is the first goal question, 140 is the second)
        const currentFormState =
          currentQuestion.id === 130 ? formState[0] : formState[1];

        // Check if the form has defaults or invalid data
        const formHasDefaultsOrInvalid =
          currentFormState.activityId === initialGoalFormState.activityId ||
          currentFormState.duration < 1;

        // Disable the next button as needed
        setNextDisabled(formHasDefaultsOrInvalid);
        return;
      }

      // Handle all non-goal questions
      const nextAllowed = currentQuestion.responses.some((x) =>
        Boolean(x.textResponse)
      );
      setNextDisabled(!nextAllowed);
    }
  }

  const handleFreeTextResponse = (e: any, option: ResponseView) => {
    const currentQuestion = questions[activeStep];
    const optIdx = currentQuestion.responses.findIndex(
      (x) => x.id === option.id
    );
    currentQuestion.responses[optIdx].textResponse = e.target.value;
    allowNext(currentQuestion);
    allowFinish(activeStep);
    setQuestions([...questions]);
  };

  function setQuestionResponse(
    currentQuestion: QuestionView,
    optIdx: number,
    e: any
  ) {
    currentQuestion.responses[optIdx].selected = Boolean(e.target.checked);
    if (e.target.checked) {
      currentQuestion.responses[optIdx].textResponse = String(e.target.value);
    }
    allowNext(currentQuestion);
    allowFinish(activeStep);
    setQuestions([...questions]);
  }

  const handleMultiSelectResponse = (e: any, option: ResponseView) => {
    const currentQuestion = questions[activeStep];
    const optIdx = currentQuestion.responses.findIndex(
      (x) => x.id === option.id
    );
    currentQuestion.responses[optIdx].textResponse = '';
    setQuestionResponse(currentQuestion, optIdx, e);
  };

  const handleMutuallyExclusiveResponse = (e: any) => {
    const currentQuestion = questions[activeStep];
    const optIdx = currentQuestion.responses.findIndex(
      (x) => x.id === Number(e.target.value)
    );
    currentQuestion.responses[optIdx].textResponse = '';
    currentQuestion.responses.forEach((x) => {
      x.selected = false;
      x.textResponse = '';
    });
    setQuestionResponse(currentQuestion, optIdx, e);
  };
  const handleAccountabilityPartnerResponse = (e: any, opt: ResponseView) => {
    const currentQuestion = questions[activeStep];
    const optIdx = currentQuestion.responses.findIndex((x) => x.id === opt.id);
    currentQuestion.responses[optIdx].textResponse = '';
    currentQuestion.responses.forEach((x) => {
      x.selected = false;
      x.textResponse = '';
    });
    currentQuestion.responses[optIdx].selected = true;
    currentQuestion.responses[optIdx].textResponse = String(e.target.value);
    allowNext(currentQuestion);
    allowFinish(activeStep);
    setQuestions([...questions]);
  };

  const handleSaveResponses = async (
    e: any,
    reason: 'GOAL' | 'SURVEY_RESPONSE'
  ) => {
    view.section!.questions = [...questions];
    if (reason === 'GOAL') {
      const rawGoals = formState.map((state) => {
        return {
          name: buildGoalText(
            state.activityId,
            state.frequency,
            state.timeframe,
            state.duration,
            state.durationTimeframe
          ),
          cohortUserId: view.cohortUserId,
          activityId: state.activityId,
          duration: state.duration,
          durationTimeframeId: state.durationTimeframe,
          frequency: state.frequency,
          timeframeId: state.timeframe,
          endDate: state.endDate,
          startDate: state.startDate,
        } as RawGoal;
      });
      await new CreateGoalAction().Execute(rawGoals);
    }

    await new SaveSurveyResponsesAction().Execute(view.section, view.groupId);

    setReload(true);

    // If the user is on the goal survey (id 0), trigger the goal modal to open
    if (view.section.id === 0) {
      handleUpdateGoal();
    }
  };

  function buildGoalText(
    activity: number,
    frequency: number,
    timeframe: number,
    duration: number,
    durationTimeframe: number
  ) {
    return `I commit to ${valueToActivityString(activity)} ${frequency} ${valueToString(timeframe)} for ${duration} ${valueToString(durationTimeframe)}`;
  }

  function onGoalResponse(e: any, option: ResponseView | undefined) {
    if (!option) {
      throw Error('Option cannot be null');
    }
    const { name, value } = e;
    const currentQuestion = questions[activeStep];
    const formStateIdx = currentQuestion.id === 130 ? 0 : 1;

    const state = { ...formState[formStateIdx] };
    const newFormState = {
      ...state,
      [name]: value,
    };
    formState[formStateIdx] = { ...newFormState };
    setFormState([...formState]);
    const optIdx = currentQuestion.responses.findIndex(
      (x) => x.id === option.id
    );
    currentQuestion.responses[optIdx].textResponse = buildGoalText(
      newFormState.activityId,
      newFormState.frequency,
      newFormState.timeframe,
      newFormState.duration,
      newFormState.durationTimeframe
    );
    allowNext(currentQuestion);
    allowFinish(activeStep);
    setQuestions([...questions]);
  }

  type CardName = 'Goal' | 'Pot' | 'Exercise' | 'Science' | 'Leader';
  type CardsEnabled = {
    [card in CardName]: { enabled: boolean; message: string };
  };

  calculateWeek1();

  const cardsEnabled: CardsEnabled = useMemo(() => {
    // Goal: day 2
    // Pot: day 4
    // Exercise: Day 8
    // Science: Day 12
    // Leader: Day 15

    // If we don't have a proper start date, disable all cards
    if (!groupStartDate) {
      logger.debug('No proper date, all cards disabled');
      return {
        Goal: {
          enabled: false,
          message:
            'Start thinking about exercise you’d like to try. The program will help you turn this into a goal soon!',
        },
        Pot: {
          enabled: false,
          message:
            'Keep showing up, and this piece of the program will be revealed before you know it.',
        },
        Exercise: {
          enabled: false,
          message:
            'Stay consistent, and this feature will unlock as you progress.',
        },
        Science: {
          enabled: false,
          message:
            'Keep up the momentum—this part of the program is just around the corner.',
        },
        Leader: {
          enabled: false,
          message:
            'You’re on your way! This feature unlocks as you advance through the program.',
        },
      };
    }

    // We know we have a date, so we can safely convert it
    const startDate = ToDate(groupStartDate);

    // Check the current date compared to when each card should be enabled
    const cards = {
      Goal: {
        enabled: !(DateTime.now() < startDate.plus({ day: 1 })),
        message:
          'Start thinking about exercise you’d like to try. The program will help you turn this into a goal soon!',
      },
      Pot: {
        enabled: !(DateTime.now() < startDate.plus({ day: 3 })),
        message:
          'Keep showing up, and this piece of the program will be revealed before you know it.',
      },
      Exercise: {
        enabled: !(DateTime.now() < startDate.plus({ day: 7 })),
        message:
          'Stay consistent, and this feature will unlock as you progress.',
      },
      Science: {
        enabled: !(DateTime.now() < startDate.plus({ day: 11 })),
        message:
          'Keep up the momentum—this part of the program is just around the corner.',
      },
      Leader: {
        enabled: !(DateTime.now() < startDate.plus({ day: 14 })),
        message:
          'You’re on your way! This feature unlocks as you advance through the program.',
      },
    };

    return cards;
  }, [reload, week1, groupStartDate]);

  const [runJoyride, setRunJoyride] = useState<boolean>(true);
  const handleJoyrideCallback = (data: CallBackProps) => {
    const cohortId = view.groupId;
    if (!cohortId || cohortId === 0) {
      // Client is not ready yet
      return;
    }
    const { action, lifecycle, index } = data;

    // If the user skips the joyride, we should skip it next time they visit the app
    if (action === ACTIONS.SKIP) {
      localStorage.setItem(`dashboard:joyrideskipped${cohortId}`, 'true');
      setRunJoyride(false);
      return;
    } else if (lifecycle === LIFECYCLE.COMPLETE && index === steps.length - 1) {
      // Note: Lifecycle is set to complete after each step of the joyride,
      // so we need to use the index to determine if the last step was completed

      // Ensure the user doesn't see the joyride again
      localStorage.setItem(`dashboard:joyrideskipped${cohortId}`, 'true');

      // Set run to false so the joyride doesn't show again. This also triggers the info dialog to show
      setRunJoyride(false);

      return;
    } else if (action === ACTIONS.START) {
      const joyrideSkipped = localStorage.getItem(
        `dashboard:joyrideskipped${cohortId}`
      );
      if (joyrideSkipped) {
        setRunJoyride(false);
      }
    }
  };

  useEffect(() => {
    // Trigger the info dialog if the joyride is skipped or ends
    if (!runJoyride && infoDialogState.infoDialogContent) {
      setInfoDialogState((prevState) => ({ ...prevState, open: true }));
    }
  }, [runJoyride, infoDialogState.infoDialogContent]);

  return (
    <Fragment>
      <Joyride
        continuous={true}
        steps={steps}
        showProgress={true}
        run={runJoyride}
        styles={{ options: { primaryColor: palette.primary.main } }}
        showSkipButton={true}
        callback={handleJoyrideCallback}
      />

      <Container sx={backgroundStyles}>
        <Stack direction={'column'} gap={'16px'}>
          <DashboardHeader loading={isLoading} view={view} />
          <div className="teamCardJoyride dashboard-card">
            <TeamCard isLoading={isLoading} view={view} />
          </div>
          {view.cohortUserId && (
            <div className="goalsCardJoyride dashboard-card">
              <GoalTrackerCard
                week1={week1}
                enabled={cardsEnabled.Goal.enabled}
                onModalClose={handleClose}
                showNewGoalDialog={showGoalModal}
                disabledMessage={cardsEnabled.Goal.message}
                isLoading={isLoading}
                activityHistoryView={activityHistoryView}
                cohortUserId={view.cohortUserId}
                onUpdateGoal={handleUpdateGoal}
                refreshActivities={() => setReload(true)}
                reloadGoals={reload}
              />
            </div>
          )}
          <div className="potCardJoyride dashboard-card">
            <PotCard
              enabled={cardsEnabled.Pot.enabled}
              disabledMessage={cardsEnabled.Pot.message}
              isLoading={isLoading}
              cohortUserId={view.cohortUserId}
              value={Intl.NumberFormat('en-US', {
                currency: 'USD',
                style: 'currency',
              }).format(view.potAmount)}
            />
          </div>
          {groupStartDate && (
            <div className="exerciseInDisguiseJoyride dashboard-card">
              <ExerciseInDisguise
                enabled={cardsEnabled.Exercise.enabled}
                groupStart={groupStartDate}
                disabledMessage={cardsEnabled.Exercise.message}
                triggerReload={(reload: boolean) => setReload(reload)}
              />
            </div>
          )}
          {groupStartDate && (
            <div className="scienceSomethingJoyride dashboard-card">
              <ScienceSomethingCard
                enabled={cardsEnabled.Science.enabled}
                startDate={groupStartDate}
                disabledMessage={cardsEnabled.Science.message}
                triggerReload={(reload) => setReload(reload)}
              ></ScienceSomethingCard>
            </div>
          )}
          <div className="leaderCardJoyride dashboard-card">
            <LeaderCard
              enabled={cardsEnabled.Leader.enabled}
              disabledMessage={cardsEnabled.Leader.message}
              leaders={view.leaders}
            ></LeaderCard>
          </div>
          <DebugPanel displayItem={view} />
        </Stack>
      </Container>
      {view.needPayment && (
        <PaymentModal
          cohortUserId={view.cohortUserId}
          groupId={view.groupId}
          open={view.needPayment}
          amount={view.pledgeAmount}
        />
      )}
      {!runJoyride && view.section && view.section.questions.length > 0 && (
        <SurveyDrawer
          surveyTitle={view.section.title}
          participants={view.team.participants}
          saveResponses={handleSaveResponses}
          nextDisabled={nextDisabled}
          finishVisible={finishVisible}
          questions={questions}
          activeStep={activeStep}
          handleNext={handleNext}
          handlePrev={handlePrev}
          goalFormState={formState}
          handleGoalResponse={onGoalResponse}
          handleFreeTextResponse={handleFreeTextResponse}
          handleMultiSelectResponse={handleMultiSelectResponse}
          handleMutuallyExclusiveResponse={handleMutuallyExclusiveResponse}
          handleAccountabilityPartnerResponse={
            handleAccountabilityPartnerResponse
          }
        />
      )}

      {/* Only show the InfoDialog if the Joyride isn't running */}
      {!runJoyride && (
        <InfoDialog
          message={infoDialogState.infoDialogContent?.content ?? ''}
          title={infoDialogState.infoDialogContent?.title ?? ''}
          onDismiss={() => {
            if (
              infoDialogState.infoDialogContent &&
              infoDialogState.infoDialogContent.title
            ) {
              localStorage.setItem(
                `dashboard:info:${infoDialogState.infoDialogContent.title}|${view.groupId}`,
                'true'
              );
            }
            setInfoDialogState({ ...infoDialogState, open: false });
            setInfoWithDismissedStatus((prev) =>
              prev.map((info) => {
                if (info.title === infoDialogState.infoDialogContent?.title) {
                  return { ...info, dismissed: true };
                }
                return info;
              })
            );
          }}
          onClose={() => {
            setInfoDialogState({ ...infoDialogState, open: false });
          }}
          onReadLater={() =>
            setInfoDialogState({ ...infoDialogState, open: false })
          }
          open={infoDialogState.open}
        ></InfoDialog>
      )}
    </Fragment>
  );
};
