import { Group } from '../models';
import { GroupView } from '../views/GroupView';
import { GroupStore } from '../api/GroupStore';

export class AdminGroupViewCreator {
  constructor(private readonly store: GroupStore = new GroupStore()) {}

  public async CreateView(group: Group): Promise<GroupView> {
    return {
      id: group.id,
      pledge: group.pledge,
      startDate: group.startDate,
      chatSid: group.chat?.sid ?? '',
      name: group.name,
      cohortUserId: group.cohortUserId,
      userCount: group.userCount,
      groupLogo: group.groupLogo,
      shortDescription: group.shortDescription,
      participants: group.cohortUsers?.map((u) => ({
        id: u.id,
        name: u.user.name,
        email: u.user.email,
        avatarUrl: u.user.avatarUrl,
        role: u.role,
        isNew: false,
        CohortUser: { id: u.id },
      })),
    } as GroupView;
  }
}
