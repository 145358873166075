import apiResponse, { BasicFetchResponse } from './ApiResponse';
import { RawGoal } from '../views/RawGoal';
import { ApiService } from './ApiService';
import { ExistingGoal } from 'views/ExistingGoal';

export class GoalStore {
  constructor(protected readonly api: ApiService = new ApiService()) {}

  public async Write(rawGoals: RawGoal[]) {
    try {
      const responses = [];
      for (const goal of rawGoals) {
        responses.push(await this.api.post('goal', goal));
      }
      const response = responses.reduce(
        (prev, next) => {
          return {
            status: Math.max(prev.status, next.status),
            data: [prev.data, next.data],
            statusText: `${prev.statusText},${next.statusText}`,
          } as BasicFetchResponse;
        },
        { status: 0, statusText: '', data: undefined } as BasicFetchResponse
      );
      if (response.status < 300) return apiResponse.Success(response);
      else {
        return apiResponse.Error(response, response.statusText);
      }
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }

  public async ReadByCohortUserId(cohortUserId: number) {
    try {
      const response = await this.api.get(`/CohortUser/${cohortUserId}/goals`);
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }

  public async AdminReadByCohortUserId(cohortUserId: number) {
    try {
      const response = await this.api.get(
        `/admin/CohortUser/${cohortUserId}/goals`
      );
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }

  public async UpdateGoal(goal: ExistingGoal) {
    try {
      const response = await this.api.put(`goal`, goal);
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }

  public async DeleteGoal(goalId: number) {
    try {
      const response = await this.api.delete(`goal/${goalId}`);
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }
}
