import React, { useState } from 'react';
import { ParticipantView } from '../../views/ParticipantView';
import {
  Box,
  Dialog,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { palette } from '../../palette';
import Avatar from '@mui/material/Avatar';
import { CohortUserGoalEditor } from './CohortUserGoalEditor';

interface ParticipantListProps {
  length: number;
  participants: ParticipantView[];
  editMode: boolean;
}

export const ParticipantList: React.FC<ParticipantListProps> = ({
  length,
  participants,
  editMode,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedParticipant, setSelectedParticipant] =
    useState<ParticipantView | null>(null);

  const handleEditGoalsClick = (participant: ParticipantView) => {
    setSelectedParticipant(participant);
    setOpenModal(true);
  };

  // Handler to close the modal.
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedParticipant(null);
  };

  return (
    <>
      <Box
        borderRadius={8}
        border={1}
        borderColor={palette.background.border}
        display={'block'}
        position={'relative'}
      >
        <Typography
          variant={'subtitle1'}
          sx={{
            backgroundColor: palette.background.paper,
            position: 'absolute',
            top: -32,
            left: 48,
            padding: 2,
          }}
        >
          Participants ({length})
        </Typography>
        <List>
          {participants.map((p) => {
            return (
              <ListItem key={p.email}>
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: palette.primary.main }}>
                    {p.email[0]}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  {p.email}
                  {editMode && (
                    <div>
                      <br />
                      <button onClick={() => handleEditGoalsClick(p)}>
                        Edit goals
                      </button>
                    </div>
                  )}
                </ListItemText>
                <ListItemSecondaryAction>{p.role}</ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Box>

      {/* Modal to edit a cohort user's goals */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="xl"
        fullWidth={true}
        slotProps={{
          backdrop: { style: { backgroundColor: 'background.paper' } },
        }}
      >
        {selectedParticipant && (
          <CohortUserGoalEditor cohortUser={selectedParticipant} />
        )}
      </Dialog>
    </>
  );
};
