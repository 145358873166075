import * as React from 'react';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useCookies } from 'react-cookie';
import { useTwilioClient } from '../../TwilioClientContext';
import { AdminGroupsViewCreator } from 'view-creator/AdminGroupsViewCreator';
import GroupViewModel, { GroupView } from '../../views/GroupView';
import GroupSelectedAction from '../../actions/Group/GroupSelectedAction';
import { GroupChangeAction } from '../../actions/Group/GroupChangeAction';
import { GroupsView } from '../../views/GroupsView';
import { UpdateGroupAction } from '../../actions/Group/UpdateGroupAction';
import { CreateGroupAction } from '../../actions/Group/CreateGroupAction';
import { GridColDef } from '@mui/x-data-grid';
import { GroupActionCell } from './GroupActionCell';
import { ActionResponse } from '../../actions/ActionResponse';
import participantView, { ParticipantView } from '../../views/ParticipantView';
import { useNavigate } from 'react-router-dom';
import logger from 'utils/logger';

export const useGroupAdmin = () => {
  const [groups, setGroups] = useState<{ groups: GroupView[] }>({ groups: [] });
  const [selectedGroup, setSelectedGroup] = useState<GroupView>({
    ...GroupViewModel.Empty,
  });
  const [groupsUpdated, setGroupsUpdated] = useState<boolean>(true);
  const [showEditGroupModal, setShowEditGroupModal] = useState<boolean>(false);
  const [showNewGroupModal, setShowNewGroupModal] = useState<boolean>(false);
  const [showEditParticipantsModal, setShowEditParticipantsModal] =
    useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const { client, loading } = useTwilioClient();
  const [twilioClient, setTwilioClient] = useState<any>(null);

  const [cookies] = useCookies(['auth', 'profile']);
  const nav = useNavigate();
  useEffect(() => {
    if (!cookies.auth) {
      nav('/');
      return;
    }

    if (groupsUpdated) {
      const viewCreator = new AdminGroupsViewCreator();
      viewCreator.CreateView().then((view: GroupsView) => {
        setGroups(view);
        setGroupsUpdated(false);
      });
    }
  }, [cookies.auth, groupsUpdated]);

  useEffect(() => {
    if (loading) return;
    if (client) setTwilioClient(client);
  }, [loading, client]);

  const groupModalClose = async (e: any, reason?: 'SAVE' | string) => {
    setShowEditGroupModal(false);
    const action = new UpdateGroupAction();
    const response = await action.Execute(selectedGroup, reason);
    if (response.status === 'ERROR') {
      console.error(response.error);
    } else {
      resetState();
      setGroupsUpdated(true);
    }
  };

  const groupSelected = async (params: any) => {
    const group = params.row as GroupView;
    const action = new GroupSelectedAction();
    const response = await action.Execute(
      group,
      params.field,
      setSelectedGroup,
      setShowEditGroupModal,
      resetState
    );
    if (response.status === 'ERROR') {
      console.error(response.error);
    }
  };

  const handleCreateGroup = () => {
    resetState();
    setShowNewGroupModal(true);
  };

  const handleGroupChange = (
    e: React.ChangeEvent<HTMLInputElement> | DateTime
  ) => {
    const action = new GroupChangeAction();
    const response = action.Execute(e, selectedGroup, setSelectedGroup);
    if (response.status === 'ERROR') {
      console.error(response.error);
    }
  };

  const handleNewGroupChange = (
    e: React.ChangeEvent<HTMLInputElement> | DateTime
  ) => {
    const action = new GroupChangeAction();
    const response = action.Execute(e, selectedGroup, setSelectedGroup);
    if (response.status === 'ERROR') {
      console.error(response.error);
    }
  };

  const resetState = () => {
    setShowEditGroupModal(false);
    setShowEditParticipantsModal(false);
    setShowNewGroupModal(false);
    setSelectedGroup({ ...GroupViewModel.Empty });
    setActiveStep(0);
  };

  const handleCancel = () => {
    setGroupsUpdated(true);
    resetState();
  };

  const handleAddParticipant = (email: string) => {
    // If email is blank or doesn't match the patter *@*.*, return
    if (!email || !/^[^@]+@[^@]+\.[^@]+$/.test(email)) {
      logger.warn('Invalid email format: ', email);
      return;
    }

    const emailToUse = email.toLowerCase();
    selectedGroup.participants.push({
      ...participantView.Empty,
      email: emailToUse,
    });
    setSelectedGroup({ ...selectedGroup });
  };

  const onRemoveParticipant = (e: any) => {
    selectedGroup.participants = selectedGroup.participants.filter(
      (x) => x.email !== e.currentTarget.value
    );
    setSelectedGroup({ ...selectedGroup });
  };

  const handleNextStep = () => {
    setActiveStep(activeStep < 2 ? activeStep + 1 : 0);
  };

  const handlePrevStep = () => {
    setActiveStep(activeStep > 0 ? activeStep - 1 : 0);
  };

  const handleShowAddParticipantDialog = () => {
    setShowEditParticipantsModal(true);
  };

  const handleParticipantModalClose = () => {
    setShowEditParticipantsModal(false);
  };

  const handleSaveGroup = async (e: any, reason: string) => {
    try {
      if (reason !== 'SAVE') return;
      if (!twilioClient) return;
      let response: ActionResponse;

      // If selectedGroup has any emails with capital letters, convert them to lowercase
      selectedGroup.participants = selectedGroup.participants.map((p) => {
        return { ...p, email: p.email.toLowerCase() };
      });

      if (selectedGroup.id > 0) {
        const action = new UpdateGroupAction();
        response = await action.Execute(selectedGroup, reason);
      } else {
        const createAction = new CreateGroupAction();
        response = await createAction.Execute(
          reason,
          selectedGroup,
          twilioClient
        );
      }

      if (response.status === 'ERROR') {
        console.error(response.error);
      } else {
        setGroupsUpdated(true);
        resetState();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleRoleChange = (e: any, participant: ParticipantView) => {
    const newRole = String(e.currentTarget.value);
    const participantIdx = selectedGroup.participants.findIndex(
      (x) => x.email === participant.email
    );

    if (e.currentTarget.checked) {
      if (participant.role === '') {
        participant.role = newRole;
        setSelectedGroup((g) => {
          g.participants[participantIdx] = { ...participant };
          return { ...g };
        });
        return;
      }
      const roles = participant.role.split('|').filter(Boolean);
      roles.push(newRole);
      if (roles.length === 1) participant.role = roles[0];
      else participant.role = roles.join('|');
    } else {
      const roles = participant.role.split('|').filter(Boolean);

      let roleIndex = roles.indexOf(newRole);
      while (roleIndex > -1) {
        roles.splice(roleIndex, 1);
        roleIndex = roles.indexOf(newRole);
      }

      participant.role = roles.join('|');
    }
    setSelectedGroup((g) => {
      g.participants[participantIdx] = { ...participant };
      return { ...g };
    });
  };

  const cols = [
    {
      type: 'string',
      headerName: 'id',
      editable: false,
      hideSortIcons: true,
      field: 'id',
      disableExport: true,
    },
    {
      type: 'string',
      headerName: 'Pledge',
      valueFormatter: (value) => {
        return Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(value);
      },
      editable: false,
      hideSortIcons: true,
      field: 'pledge',
      disableExport: true,
    },
    {
      type: 'string',
      headerName: 'Name',
      flex: 1,
      hideSortIcons: true,
      editable: false,
      field: 'name',
      disableExport: true,
      minWidth: 200,
    },
    {
      type: 'date',
      headerName: 'Start Date',
      hideSortIcons: true,
      editable: false,
      field: 'startDate',
      disableExport: true,
      minWidth: 200,
      valueGetter: (value) => {
        if (typeof value === 'string') {
          return DateTime.fromISO(value).toJSDate();
        }
        if (DateTime.isDateTime(value)) {
          return (value as DateTime).toJSDate();
        }
      },
    },
    {
      field: 'ACTIONS',
      headerName: 'Actions',
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <GroupActionCell
            group={params.row}
            updateSummary={() => setGroupsUpdated(true)}
          ></GroupActionCell>
        );
      },
    },
  ] as GridColDef[];

  return {
    groups,
    selectedGroup,
    showEditGroupModal,
    showNewGroupModal,
    showEditParticipantsModal,
    activeStep,
    handleCreateGroup,
    groupModalClose,
    groupSelected,
    handleGroupChange,
    handleNewGroupChange,
    handleCancel,
    handleAddParticipant,
    onRemoveParticipant,
    handleNextStep,
    handlePrevStep,
    handleShowAddParticipantDialog,
    handleParticipantModalClose,
    handleSaveGroup,
    handleRoleChange,
    cols,
  };
};
