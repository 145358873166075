export enum GoalStatus {
  Succeeded = 1,
  Failed = 0,
  Unknown = -1,
}

export const GoalStatusValues: {
  [key: number]: string;
} = {
  [GoalStatus.Succeeded]: 'Succeeded',
  [GoalStatus.Failed]: 'Failed',
  [GoalStatus.Unknown]: 'Unknown',
};
